import { REDUCER_ACTIONS, SAGA_ACTIONS } from './types';

export const ActionLoginRequested = (email: string, password: string, token: string) => ({
  type: SAGA_ACTIONS.LOGIN_REQUESTED,
  payload: {
    email,
    password,
    token,
  },
});

export const ActionLoginSucceeded = (token: string, email: string, isLogged: boolean) => ({
  type: REDUCER_ACTIONS.LOGIN_SUCCEEDED,
  payload: {
    token,
    email,
    isLogged,
  },
});

export const ActionLogoutRequested = () => ({
  type: SAGA_ACTIONS.LOGOUT_REQUESTED,
  payload: {},
});

export const ActionLogoutSucceeded = () => ({
  type: REDUCER_ACTIONS.LOGOUT_SUCCEEDED,
  payload: {},
});

export const ActionSetIsLoginProcessingReceived = (isLoginProcessing: boolean) => ({
  type: REDUCER_ACTIONS.SET_IS_LOGIN_PROCESSING_RECEIVED,
  payload: {
    isLoginProcessing,
  },
});

import { Suspense, lazy } from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ApplicationState, ROUTES } from 'src/store';
import LoadingSpinner from 'src/components/LoadingSpinner';

export const App = () => {
  const userSlice = useSelector((state: ApplicationState) => state.userReducer);
  const HomePage = lazy(() => import('src/pages/HomePage'));
  const Dashboard = lazy(() => import('src/pages/Dashboard'));
  const PlanPage = lazy(() => import('src/pages/PlanPage'));
  const LoginPage = lazy(() => import('src/pages/LoginPage'));
  const CreatePlanPage = lazy(() => import('src/pages/CreatePlanPage'));
  const NotFound = lazy(() => import('src/pages/NotFound'));

  if (!userSlice.isLogged) {
    return (
      <Suspense fallback={<LoadingSpinner />}>
        <Router>
          <Routes>
            <Route path={ROUTES.HOME} element={<HomePage />} />
            <Route path={ROUTES.LOGIN} element={<LoginPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Router>
        <Routes>
          <Route path={ROUTES.HOME} element={<Dashboard />} />
          <Route path={ROUTES.LOGIN} element={<LoginPage />} />
          <Route path={ROUTES.PLAN} element={<PlanPage />} />
          <Route path={ROUTES.PLAN_CREATE} element={<CreatePlanPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default App;

import { produce } from 'immer';
import { ErrorMsgState, REDUCER_ACTIONS } from './types';

const INIT_STATE: ErrorMsgState = {
  isVisible: false,
  message: '',
};

const errorMsgReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case REDUCER_ACTIONS.SHOW_ERROR_MSG_RECEIVED:
      return produce(state, (draft) => {
        draft.isVisible = true;
        draft.message = action.payload.message;
        return draft;
      });
    case REDUCER_ACTIONS.HIDE_ERROR_MSG_RECEIVED:
      return produce(state, (draft) => {
        draft.isVisible = false;
        draft.message = '';
        return draft;
      });
    default:
    //
  }

  return state;
};

export default errorMsgReducer;

import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import { userReducer, userSaga, userTypes } from 'src/store/user';
import { planReducer, planSaga, planTypes } from 'src/store/plan';
import { errorMsgReducer, errorMsgSaga, errorMsgTypes } from 'src/store/errorMsg';

export interface ApplicationState {
  userReducer: userTypes.UserState;
  planReducer: planTypes.PlanState;
  errorMsgReducer: errorMsgTypes.ErrorMsgState;
}

export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  PLAN: '/plans',
  PLAN_CREATE: '/create/plan'
}

export const rootReducer = combineReducers<ApplicationState>({
  userReducer,
  planReducer,
  errorMsgReducer,
});

export const rootSaga = function* () {
  yield all([...userSaga, ...planSaga, ...errorMsgSaga]);
};

export const setupAxios = (axios: any, store: any) => {
  axios.interceptors.request.use(
    (config: any) => {
      const {
        userReducer: { token },
      } = store.getState();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (err: any) => Promise.reject(err),
  );
};

import { PaginatorInfo, Plan } from 'src/models';

export const SAGA_ACTIONS = {
  FETCH_REQUESTED: 'plan/FETCH_REQUESTED',
  CREATE_REQUESTED: 'plan/CREATE_PLAN_REQUESTED',
};

export const REDUCER_ACTIONS = {
  FETCH_SUCCEEDED: 'plan/LOGIN_SUCCEEDED',
  CREATE_SUCCEEDED: 'plan/CREATE_PLAN_SUCCEEDED',
};

export interface PlanState {
  plan: Plan;
  plans: {
    paginatorInfo: PaginatorInfo;
    data: Plan[];
  };
}

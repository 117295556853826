import { SAGA_ACTIONS, REDUCER_ACTIONS } from './types';
import { PaginatorInfo, Plan } from 'src/models';

export const ActionFetchRequested = (first: number = 15, page: number = 1) => ({
	type: SAGA_ACTIONS.FETCH_REQUESTED,
	payload: {
		first,
		page,
	},
});

export const ActionFetchReceived = (paginatorInfo: PaginatorInfo, data: Plan[]) => ({
	type: REDUCER_ACTIONS.FETCH_SUCCEEDED,
	payload: {
		paginatorInfo,
		data,
	},
});

export const ActionCreateRequested = (plan: Plan) => ({
	type: SAGA_ACTIONS.CREATE_REQUESTED,
	payload: {
		plan,
	},
});

export const ActionCreateReceived = (plan: Plan) => ({
	type: REDUCER_ACTIONS.CREATE_SUCCEEDED,
	payload: {
		plan,
	},
});

import { put, takeLatest } from 'redux-saga/effects';
import { SAGA_ACTIONS } from './types';
import * as errorMsgActions from './actions';

function* showErrorMsgRequested(action: any) {
	try {
		const { message } = action.payload;
		yield put(errorMsgActions.ActionShowErrorMsgReceived(message));
	} catch (error: any) {
		//
	}
}

function* hideErrorMsgRequested() {
	try {
		yield put(errorMsgActions.ActionHideErrorMsgReceived());
	} catch (error: any) {
		//
	}
}

const errorMsgSaga = [
	takeLatest(SAGA_ACTIONS.SHOW_ERROR_MSG_REQUESTED, showErrorMsgRequested),
	takeLatest(SAGA_ACTIONS.HIDE_ERROR_MSG_REQUESTED, hideErrorMsgRequested),
];

export default errorMsgSaga;

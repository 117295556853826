import { call, put, take, takeLatest } from 'redux-saga/effects';
import { SAGA_ACTIONS } from './types';
import { userActions } from 'src/store/user';
import { errorMsgActions } from 'src/store/errorMsg';
import { get } from 'lodash';
import { EventChannel } from 'redux-saga';
import { IChannelEvents, IEventType } from 'src/api';
import * as api from 'src/api/user';

function* loginRequested(action: any) {
  try {
    const { email, password, token } = action.payload;
    try {
      yield put(userActions.ActionSetIsLoginProcessingReceived(true));
      const channel: EventChannel<any> = yield call(api.login, email, password, token);
      while (true) {
        const next: IChannelEvents = yield take(channel);
        switch (next.type) {
          case IEventType.RESULT: {
            const _token: string | null = get(next.payload.result, 'data.data.login.token', null);
            if (_token) {
              yield put(userActions.ActionSetIsLoginProcessingReceived(false));
              yield put(userActions.ActionLoginSucceeded(_token, email, true));
            }
            break;
          }
          case IEventType.ERROR: {
            break;
          }
          default:
          //
        }
      }
    } finally {
      yield put(userActions.ActionSetIsLoginProcessingReceived(false));
    }
  } catch (error: any) {
    yield put(errorMsgActions.ActionShowErrorMsgRequested(error.message));
  }
}

function* logoutRequested(action: any) {
  try {
    yield put(userActions.ActionLogoutSucceeded());
  } catch (error: any) {
    yield put(errorMsgActions.ActionShowErrorMsgRequested(error.message));
  }
}

const userSaga: any = [
  takeLatest(SAGA_ACTIONS.LOGIN_REQUESTED, loginRequested),
  takeLatest(SAGA_ACTIONS.LOGOUT_REQUESTED, logoutRequested),
];

export default userSaga;

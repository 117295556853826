import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { SAGA_ACTIONS } from './types';
import { planActions } from 'src/store/plan';
import { get } from 'lodash';
import * as api from 'src/api/plans';
import { Plan } from 'src/models';

function* fetchRequested(action: any) {
  try {
    const { first, page } = action.payload;
    const result: AxiosResponse = yield call(api.fetchPlan, first, page);
    const plans = get(result, 'data.data.plans', null);
    if (plans) {
      yield put(planActions.ActionFetchReceived(plans.paginatorInfo, plans.data));
    }
  } catch (error: any) {
    //
  }
}

function* createPlanRequested(action: any) {
  try {
    const plan: Plan = action.payload.plan;
    const result: AxiosResponse = yield call(api.createPlan, { ...plan });
    const _plan = get(result, 'data.data.plan', null);
    if (_plan) {
      yield put(planActions.ActionCreateReceived(_plan));
    }
  } catch (error: any) {
    //
  }
}

const planSaga = [
  takeLatest(SAGA_ACTIONS.FETCH_REQUESTED, fetchRequested),
  takeLatest(SAGA_ACTIONS.CREATE_REQUESTED, createPlanRequested),
];

export default planSaga;

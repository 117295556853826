import ReactDOM from 'react-dom';
import { App, AuthInit } from 'src/app';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { rootSaga, rootReducer, setupAxios } from 'src/store';
import { createStore, applyMiddleware, compose } from 'redux';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import storage from 'redux-persist/lib/storage';
import axios from 'axios';
import createSagaMiddleware from 'redux-saga';
import 'focus-visible/dist/focus-visible';
import 'src/index.css';

const colors = {
  brand: {
    primary: '#47328a',
    secondary: '#d3d0e7',
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac',
  },
};
const theme = extendTheme({ colors });
const persistConfig = {
  key: 'spi_root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
let middleware = [applyMiddleware(sagaMiddleware)];
if ((window as any).__REDUX_DEVTOOLS_EXTENSION__) {
  middleware = [...middleware, (window as any).__REDUX_DEVTOOLS_EXTENSION__()];
}
const store = createStore(persistedReducer, compose(...middleware));
const persistor = persistStore(store);

setupAxios(axios, store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ChakraProvider theme={theme}>
        <AuthInit>
          <App />
        </AuthInit>
      </ChakraProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
serviceWorker.unregister();
reportWebVitals();
sagaMiddleware.run(rootSaga);

import axios from 'axios';
import { CREATE_PLAN } from 'src/graphql/mutation';
import { FETCH_PLANS } from 'src/graphql/query';
import { print } from 'graphql';
import { Plan } from 'src/models';

export const createPlan = (plan: Plan) => {
  return axios.post(
    `${process.env.REACT_APP_HOST}/graphql`,
    {
      query: print(CREATE_PLAN),
      variables: {
        ...plan,
      },
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
};

export const fetchPlan = (first: number = 15, page: number = 1) => {
  return axios.post(
    `${process.env.REACT_APP_HOST}/graphql`,
    {
      query: print(FETCH_PLANS),
      variables: {
        first,
        page,
      },
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
};

import { produce } from 'immer';
import { REDUCER_ACTIONS, UserState } from './types';

const INIT_STATE: UserState = {
  email: '',
  token: '',
  isLogged: false,
  isLoginProcessing: false,
};

const userReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case REDUCER_ACTIONS.LOGIN_SUCCEEDED:
      return produce(state, (draft) => {
        draft.email = action.payload.email;
        draft.token = action.payload.token;
        draft.isLogged = action.payload.isLogged;
        return draft;
      });
    case REDUCER_ACTIONS.LOGOUT_SUCCEEDED:
      return produce(state, (draft) => {
        draft.email = '';
        draft.token = '';
        draft.isLogged = false;
        return draft;
      });
    case REDUCER_ACTIONS.SET_IS_LOGIN_PROCESSING_RECEIVED:
      return produce(state, (draft) => {
        draft.isLoginProcessing = action.payload.isLoginProcessing;
        return draft;
      });
    default:
    //
  }

  return state;
};

export default userReducer;

export enum IEventType {
  'PROGRESS',
  'RESULT',
  'ERROR',
}

export interface IChannelEvents {
  type: IEventType;
  payload: {
    result?: any;
    percent?: number;
    error?: any;
  };
}

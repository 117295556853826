import { gql } from 'graphql-tag';

export const LOGIN = gql`
  mutation ($email: String!, $password: String!, $device_name: String!, $recaptcha: String!) {
    login(email: $email, password: $password, device_name: $device_name, recaptcha: $recaptcha) {
      email
      name
      password
      token
      status {
        code
        message
      }
    }
  }
`;

export const CREATE_PLAN = gql`
  mutation ($name: String!, $description: String!, $slug: String!, $cost: Float) {
    createPlan(name: $name, description: $description, slug: $slug, cost: $cost) {
      id
      name
      description
      slug
      stripe_plan
      cost
    }
  }
`;

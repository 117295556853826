import { FC, useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMe } from 'src/api/user';
import { ApplicationState } from 'src/store';
import { get } from 'lodash';
import { AxiosResponse } from 'axios';
import { userActions } from 'src/store/user';
import LoadingSpinner from 'src/components/LoadingSpinner';

const AuthInit: FC<any> = (props) => {
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const userSlice = useSelector((state: ApplicationState) => state.userReducer);

  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const result: AxiosResponse = await getMe(userSlice.token);
          const me = get(result, `data.data.me`, null);
          if (!me) {
            dispatch(userActions.ActionLogoutRequested());
          }
        }
      } catch (error) {
        if (!didRequest.current) {
          dispatch(userActions.ActionLogoutRequested());
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (userSlice.token) {
      requestUser();
    } else {
      dispatch(userActions.ActionLogoutRequested());
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LoadingSpinner /> : <>{props.children}</>;
};

export default AuthInit;

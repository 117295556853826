import axios from 'axios';
import { LOGIN } from 'src/graphql/mutation';
import { FETCH_ME, FETCH_USERS } from 'src/graphql/query';
import { print } from 'graphql';
import { END, eventChannel } from 'redux-saga';
import { IEventType, IChannelEvents } from 'src/api';

export const login = (email: string, password: string, token: string) => {
  return eventChannel((emitter) => {
    axios
      .get(`${process.env.REACT_APP_HOST}/sanctum/csrf-cookie`)
      .then((response) => {
        axios
          .post(
            `${process.env.REACT_APP_HOST}/graphql`,
            {
              query: print(LOGIN),
              variables: {
                email,
                password,
                recaptcha: token,
                device_name: 'Web',
              },
            },
            {
              headers: {
                Accept: 'application/json',
              },
            },
          )
          .then((result) => {
            emitter({
              type: IEventType.RESULT,
              payload: { result },
            } as IChannelEvents);
            emitter(END);
          })
          .catch((error) => {
            emitter({
              type: IEventType.ERROR,
              payload: { error },
            } as IChannelEvents);
            emitter(END);
          });
      })
      .catch((error) => {
        emitter({
          type: IEventType.ERROR,
          payload: { error },
        } as IChannelEvents);
        emitter(END);
      });

    return () => {};
  });
};

export const getMe = (token: string) => {
  return axios.post(
    `${process.env.REACT_APP_HOST}/graphql`,
    {
      query: print(FETCH_ME),
    },
    {
      headers: {
        Accept: `application/json`,
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const fetchUsers = () => {
  return axios.post(
    `${process.env.REACT_APP_HOST}/graphql`,
    {
      query: print(FETCH_USERS),
    },
    {
      headers: {
        Accept: `application/json`,
      },
    },
  );
};

import { gql } from 'graphql-tag';

export const FETCH_PLANS = gql`
  query ($first: Int!, $page: Int!) {
    plans(first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        name
        description
        slug
        cost
      }
    }
  }
`;

export const FETCH_ME = gql`
  query {
    me {
      id
      name
    }
  }
`;

export const FETCH_USERS = gql`
  query($first: Int, $page: Int) {
    users(first: $first, page: $page) {
      paginatorInfo {
          currentPage
          perPage
          count
          total
      }
      data {
          id
          first_name
          last_name
      }
    }
  }
`

import { FC } from 'react';
import { Box, Spinner, Text } from '@chakra-ui/react';

const LoadingSpinner: FC<any> = () => {
	return (
		<>
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				position="absolute"
				top="0"
				left="0"
				height="100vh"
				width="100vw"
			>
				<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
					<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="brand.primary" size="lg" mb="5px" />
					<Text>Loading...</Text>
				</Box>
			</Box>
		</>
	);
};

export default LoadingSpinner;

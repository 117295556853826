export const SAGA_ACTIONS = {
  LOGIN_REQUESTED: 'user/LOGIN_REQUESTED',
  LOGOUT_REQUESTED: 'user/LOGOUT_REQUESTED',
};

export const REDUCER_ACTIONS = {
  LOGIN_SUCCEEDED: 'user/LOGIN_SUCCEEDED',
  LOGOUT_SUCCEEDED: 'user/LOGOUT_SUCCEEDED',
  SET_IS_LOGIN_PROCESSING_RECEIVED: 'user/IS_LOGIN_PROCESSING_RECEIVED',
};

export interface UserState {
  isLogged: boolean;
  token: string;
  email: string;
  isLoginProcessing: boolean;
}

import { REDUCER_ACTIONS, SAGA_ACTIONS } from './types';

export const ActionShowErrorMsgRequested = (message: string) => ({
	type: SAGA_ACTIONS.SHOW_ERROR_MSG_REQUESTED,
	payload: {
		message,
	},
});

export const ActionShowErrorMsgReceived = (message: string) => ({
	type: REDUCER_ACTIONS.SHOW_ERROR_MSG_RECEIVED,
	payload: {
		message,
	},
});

export const ActionHideErrorMsgRequested = () => ({
	type: SAGA_ACTIONS.HIDE_ERROR_MSG_REQUESTED,
	payload: {},
});

export const ActionHideErrorMsgReceived = () => ({
	type: REDUCER_ACTIONS.HIDE_ERROR_MSG_RECEIVED,
	payload: {},
});

import { produce } from 'immer';
import { REDUCER_ACTIONS, PlanState } from './types';

const INIT_STATE: PlanState = {
  plan: {
    name: '',
    description: '',
    slug: '',
    cost: 0,
  },
  plans: {
    paginatorInfo: {
      count: 0,
      currentPage: 1,
      firstItem: 0,
      hasMorePages: false,
      lastItem: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
    },
    data: [],
  },
};

const userReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case REDUCER_ACTIONS.FETCH_SUCCEEDED:
      return produce(state, (draft) => {
        const { paginatorInfo, data } = action.payload;
        draft.plans = {
          paginatorInfo: {
            ...draft.plans.paginatorInfo,
            count: paginatorInfo.count,
            firstItem: paginatorInfo.firstItem,
            hasMorePages: paginatorInfo.hasMorePages,
            lastItem: paginatorInfo.lastItem,
            lastPage: paginatorInfo.lastPage,
            total: paginatorInfo.total,
          },
          data,
        };
        return draft;
      });
    case REDUCER_ACTIONS.CREATE_SUCCEEDED:
      return produce(state, (draft) => {
        const { plan } = action.payload;
        draft.plan = plan;
        return draft;
      });
    default:
    //
  }

  return state;
};

export default userReducer;

export const SAGA_ACTIONS = {
	SHOW_ERROR_MSG_REQUESTED: 'errorMsg/SHOW_ERROR_MSG_REQUESTED',
	HIDE_ERROR_MSG_REQUESTED: 'errorMsg/HIDE_ERROR_MSG_REQUESTED',
};

export const REDUCER_ACTIONS = {
	SHOW_ERROR_MSG_RECEIVED: 'errorMsg/SHOW_ERROR_MSG_RECEIVED',
	HIDE_ERROR_MSG_RECEIVED: 'errorMsg/HIDE_ERROR_MSG_RECEIVED',
};

export interface ErrorMsgState {
	isVisible: boolean;
	message: string;
}
